<template>
  <PageWrapper>
    <div class="w-full">
      <div class="flex justify-start items-center flex-wrap mx-4">
        <button class="py-2 px-3 text-sm border-b hover:border-primary">
          Accounts
        </button>
        <button class="py-2 px-3 text-sm border-b hover:border-primary">
          Outreach
        </button>
        <button class="py-2 px-3 text-sm border-b hover:border-primary">
          Applications
        </button>
        <button class="py-2 px-3 text-sm border-b hover:border-primary">
          Notes
        </button>
        <button class="py-2 px-3 text-sm border-b hover:border-primary">
          Attachments
        </button>
      </div>
    </div></PageWrapper
  >
</template>

<script>
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
export default {
  components: {
    PageWrapper,
  },
};
</script>
